import React from 'react';
import { graphql } from 'gatsby';
import WorkTile from '../components/WorkTile';
import SEO from '../components/SEO';
import CTA from '../components/Cta';
import FadeIn from '../components/Animations/FadeIn';

import AnimatedHeading from '../components/AnimatedHeading';

const WorkPage = ({ data }) => {
	const work = data.allSanityProject.nodes;
	return (
		<>
			<SEO
				title="Home"
				pathname="/"
				desc="A collaboration of Architects & Master Craftsman"
			/>
			<AnimatedHeading text="A collaboration of Architects & Master Craftsman" />
			<section>
				{work.map((work) => (
					<WorkTile
						key={work.id}
						title={work.title}
						link={`projects/` + work.slug.current}
						image={work.mainImage.asset.gatsbyImageData}
					/>
				))}
			</section>
			<FadeIn>
				<CTA />
			</FadeIn>
		</>
	);
};

export default WorkPage;

export const query = graphql`
	query WorkQuery {
		allSanityProject(sort: { fields: [publishedAt], order: DESC }) {
			nodes {
				id
				title
				publishedAt
				slug {
					current
				}
				mainImage {
					asset {
						gatsbyImageData(
							layout: FULL_WIDTH
							fit: FILLMAX
							placeholder: BLURRED
						)
					}
				}
			}
		}
	}
`;
