import * as React from 'react';
import { Link } from 'gatsby';

export default function CTA() {
	return (
		<section className="w-full px-6 md:px-8 py-32 text-sm border-t border-white border-opacity-10">
			<div className="text-center max-w-3xl mx-auto">
				<Link to="/contact" className="link">
					<h2>Get in touch</h2>
				</Link>
			</div>
		</section>
	);
}
