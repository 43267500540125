import React, { useRef, useLayoutEffect } from 'react';
import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { GatsbyImage } from 'gatsby-plugin-image';
import './style.scss';

export default function WorkTile(props) {
	var el = useRef(null);

	useLayoutEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		gsap.set(el.current, {
			autoAlpha: 0,
			y: 100,
		});

		gsap.to(el.current, {
			autoAlpha: 1,
			y: 0,
			ease: 'power4.out',
			duration: 2,
			scrollTrigger: {
				//markers: true,
				trigger: el.current,
				start: 'top 90%',
				end: 'bottom top',
				stagger: 0.5,
			},
		});
	});

	return (
		<Link to={props.link}>
			<div
				className="aspect-square md:aspect-video relative overflow-hidden"
				ref={el}
			>
				<GatsbyImage
					image={props.image}
					alt={props.title + ' project image'}
					className="absolute inset-0 h-full w-full object-cover z-0"
				/>
				<div className="absolute inset-0 z-10 p-8">
					<h2 className="link">{props.title || 'Work title'}</h2>
				</div>
			</div>
		</Link>
	);
}
